import React, { FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as Sentry from '@sentry/react';

import { Loader } from '@heltti/components';

import { eidentVerification } from '../../ducks';
import { RouteComponentProps } from 'react-router';

type EidentHandlerProps = ConnectedProps<typeof connector> & RouteComponentProps;

const EidentHandler: FunctionComponent<EidentHandlerProps> = (props) => {
    const { history, verify } = props;

    useEffect(() => {
        const initiateVerification = async () => {
            try {
                await verify(window.location.search);

                history.push('/');
            } catch (exception) {
                const searchParams = new URLSearchParams(window.location.search);
                const error = searchParams.get('error') ?? 'unknown';
                const code = searchParams.get('code') ?? 'unknown';

                if (code !== 'cancel') {
                    Sentry.captureMessage(`Eident: ${error} ${code}`, 'error');
                }

                history.push('/e-ident/error');
            }
        };

        void initiateVerification();
    }, [history, verify]);

    return <Loader />;
};

const connector = connect(null, { verify: eidentVerification });

export const Eident = connector(EidentHandler);
