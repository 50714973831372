import React, { FunctionComponent, useEffect } from 'react';
import { Route, RouteProps, useLocation } from 'react-router';

import { AppLanguage, useAppLanguage } from '../context/AppLanguage';

export const PublicRoute: FunctionComponent<RouteProps> = (props) => {
    const { search } = useLocation();
    const { setLanguage } = useAppLanguage();

    const lang = new URLSearchParams(search).get('lang');

    useEffect(() => {
        setLanguage(
            lang && Object.keys(AppLanguage).includes(lang.toUpperCase()) ? (lang as AppLanguage) : AppLanguage.FI
        );
    }, [lang, setLanguage]);

    return <Route {...props} />;
};
