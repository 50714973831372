import React, { useEffect } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DropdownProps, Form, Header } from 'semantic-ui-react';

import { Button, DateRangePicker } from '@heltti/components';

import { CompanyInsuranceType, InsuranceCompanyListFragment, LocationListFragment } from '../graphql-schema';

export interface InsuranceFormProps {
    mode: 'edit' | 'create';
    loading?: boolean;
    validate?: boolean;
    initialValues: InsuranceFormData;
    insuranceCompanies: InsuranceCompanyListFragment[];
    locations: LocationListFragment[];
    onSubmit: (values: InsuranceFormData) => Promise<void>;
}

export type InsuranceFormData = {
    type?: CompanyInsuranceType;
    name?: string;
    number?: string;
    excess?: number;
    instructionsMember?: string;
    validFrom?: Date;
    validUntil?: Date;
    insuranceCompanyId?: string;
    preferredLocationId?: string;
};

const schema = (intl: IntlShape) => {
    const required = intl.formatMessage({ id: 'form.validate.required' });

    return yup.object().shape({
        type: yup.mixed<CompanyInsuranceType>().oneOf(Object.values(CompanyInsuranceType)).required(),
        name: yup.string(),
        number: yup.string(),
        excess: yup.number().min(0.0).required(),
        instructionsMember: yup.string(),
        validFrom: yup.date(),
        validUntil: yup.date().nullable(),
        insuranceCompanyId: yup.string().required(required)
    });
};

const insuranceTypeOptions = (intl: IntlShape) =>
    Object.entries(CompanyInsuranceType).map(([key, type]) => ({
        key,
        value: type,
        text: intl.formatMessage({ id: `company.insurances.type.${type}` })
    }));

const insuranceCompanyOptions = (insuranceCompanies: InsuranceCompanyListFragment[]) =>
    insuranceCompanies.map(({ id, name }: InsuranceCompanyListFragment) => ({
        key: id,
        value: id,
        text: name
    }));

const preferredLocationOptions = (preferredLocations: LocationListFragment[]) =>
    preferredLocations.map(({ id, name }: LocationListFragment) => ({
        key: id,
        value: id,
        text: name
    }));

export const InsuranceForm: React.FC<InsuranceFormProps> = (props) => {
    const intl = useIntl();
    const { mode, loading, validate, initialValues, insuranceCompanies, locations, onSubmit } = props;

    const { handleChange, validateForm, values, errors, dirty, isValid, setFieldValue } = useFormik<InsuranceFormData>({
        enableReinitialize: true,
        initialValues,
        onSubmit,
        validationSchema: schema(intl)
    });

    useEffect(() => {
        if (validate) {
            void validateForm();
        }
    }, [validate, validateForm]);

    const renderInsuranceFields = () => {
        const { type, number, name, excess, insuranceCompanyId, preferredLocationId } = values;
        return (
            <>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Select
                            required
                            id="type"
                            name="type"
                            value={type ?? ''}
                            options={insuranceTypeOptions(intl)}
                            onChange={(_, data: DropdownProps) => {
                                void setFieldValue('type', data.value);
                            }}
                            label={intl.formatMessage({ id: 'form.insurance.insurance.type' })}
                            placeholder={intl.formatMessage({ id: 'form.insurance.insurance.type' })}
                            error={!!errors?.type}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Select
                            required
                            id="insuranceCompanyId"
                            name="insuranceCompanyId"
                            value={insuranceCompanyId ?? ''}
                            options={insuranceCompanyOptions(insuranceCompanies)}
                            onChange={(_, data: DropdownProps) => {
                                void setFieldValue('insuranceCompanyId', data.value);
                            }}
                            label={intl.formatMessage({ id: 'form.insurance.company' })}
                            placeholder={intl.formatMessage({ id: 'form.insurance.company' })}
                            error={!!errors?.type}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Select
                            id="preferredLocationId"
                            name="preferredLocationId"
                            value={preferredLocationId ?? ''}
                            options={preferredLocationOptions(locations)}
                            onChange={(_, data: DropdownProps) => {
                                void setFieldValue('preferredLocationId', data.value);
                            }}
                            label={intl.formatMessage({ id: 'form.insurance.location' })}
                            placeholder={intl.formatMessage({ id: 'form.insurance.location' })}
                            error={!!errors?.type}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            id="name"
                            name="name"
                            value={name ?? ''}
                            onChange={handleChange}
                            error={!!errors?.name}
                            label={intl.formatMessage({ id: 'form.insurance.insurance.name' })}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            id="number"
                            name="number"
                            value={number ?? ''}
                            onChange={handleChange}
                            error={!!errors?.number}
                            label={intl.formatMessage({ id: 'form.insurance.insurance.number' })}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Input
                            required
                            id="excess"
                            name="excess"
                            value={excess ?? ''}
                            onChange={handleChange}
                            error={!!errors?.excess}
                            label={intl.formatMessage({ id: 'form.insurance.insurance.excess' })}
                        />
                    </Form.Field>
                </Form.Group>
            </>
        );
    };

    const renderValidityFields = () => {
        const { validFrom, validUntil } = values;
        return (
            <Form.Group widths="equal">
                <Form.Field
                    control={DateRangePicker}
                    clearButton
                    initialRange={[
                        validFrom ? new Date(validFrom) : undefined,
                        validUntil ? new Date(validUntil) : undefined
                    ]}
                    onRangeChange={([from, until]) => {
                        void setFieldValue('validFrom', from ?? undefined);
                        void setFieldValue('validUntil', until ?? undefined);
                    }}
                    placeholders={[
                        intl.formatMessage({ id: 'form.insurance.validity.valid_from' }),
                        intl.formatMessage({ id: 'form.insurance.validity.valid_until' })
                    ]}
                    label={intl.formatMessage({ id: 'form.insurance.validity' })}
                    error={!validFrom || !!errors?.validFrom}
                />
            </Form.Group>
        );
    };

    const renderInstructionsFields = () => {
        const { instructionsMember } = values;
        return (
            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        id="instructionsMember"
                        name="instructionsMember"
                        value={instructionsMember ?? ''}
                        onChange={handleChange}
                        error={!!errors?.instructionsMember}
                        label={intl.formatMessage({ id: 'form.insurance.instructions' })}
                    />
                </Form.Field>
            </Form.Group>
        );
    };

    return (
        <>
            <Header as="h4">
                <FormattedMessage id={`form.insurance.title.${mode}`} />
            </Header>
            <Form
                onSubmit={() => {
                    void onSubmit(values);
                }}
            >
                {renderInsuranceFields()}
                {renderValidityFields()}
                {renderInstructionsFields()}
                <Button
                    primary
                    floated="right"
                    formNoValidate
                    type="submit"
                    disabled={!dirty || !isValid}
                    loading={loading}
                >
                    <FormattedMessage id="form.insurance.save" />
                </Button>
            </Form>
        </>
    );
};
