import React from 'react';
import { useIntl } from 'react-intl';

import { Message } from 'semantic-ui-react';

import t from '../translations';

type Props = {
    type: 'members' | 'sickLeave';
};

export const DataIntegrationBanner: React.FC<Props> = (props) => {
    const intl = useIntl();
    const { type } = props;

    return (
        <Message>
            <Message.Content>
                <Message.Header>
                    {type === 'members' && intl.formatMessage(t.memberDataIntegrationEnabled)}
                    {type === 'sickLeave' && intl.formatMessage(t.sickLeaveDataIntegrationEnabled)}
                </Message.Header>
            </Message.Content>
        </Message>
    );
};
