import { IntlShape } from 'react-intl';

import { billingCategoryOptions } from '../library/constants';

export const translatedBillingCategoryOptions = (intl: IntlShape): any[] => {
    const translatedCategories: { value: string; text: string }[] = [];
    const categoryTranslationKeys = Object.keys(billingCategoryOptions);

    for (let i = 0; i < categoryTranslationKeys.length; i++) {
        const translation = categoryTranslationKeys[i];

        translatedCategories.push({
            value: translation,
            text: intl.formatMessage(billingCategoryOptions[translation])
        });
    }

    return translatedCategories;
};

export const translateBillingCategory = (intl: IntlShape, category: string) => {
    return intl.formatMessage(billingCategoryOptions[category]);
};
