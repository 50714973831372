import { createStore as reduxCreateStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

function getInitialState() {
    return {};
}

export function createStore(reducers: any, middlewares: any) {
    return reduxCreateStore(
        combineReducers({ ...reducers }),
        getInitialState(),
        composeWithDevTools(applyMiddleware(...[thunk], ...middlewares))
    );
}
