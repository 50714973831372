import React from 'react';
import { Card, Header, Image, Divider, Button } from 'semantic-ui-react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { Content } from '@heltti/components';

import './EidentError.less';

import support from '../../assets/images/support.png';

export const EidentError = injectIntl<WrappedComponentProps>(({ intl }) => {
    const supportButtonClicked = () => {
        const link = `mailto:${intl.formatMessage({ id: 'support.email' })}`;
        window.location.href = link;
    };

    return (
        <Content id="e-indent-error-page" containerWidth="wide">
            <Card fluid centered>
                <Card.Content>
                    <Card.Header>
                        <Header as="h1">
                            <FormattedMessage id="signUp.error.title" />
                        </Header>
                    </Card.Header>

                    <Divider hidden />

                    <Image src={support} />

                    <Card.Description>
                        <Divider hidden />
                        <FormattedMessage id="signUp.error.description" />
                    </Card.Description>

                    <Button negative onClick={supportButtonClicked}>
                        <FormattedMessage id="signUp.error.contact_support" />
                    </Button>
                </Card.Content>
            </Card>
        </Content>
    );
});
