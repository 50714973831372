import {
    acceptTerms as apiAcceptTerms,
    authenticate,
    callEIdentVerification,
    logout as apiLogout,
    refreshAuthentication as apiRefreshAuthentication
} from '../api';
import { AnyAction } from 'redux';

export const LOGIN_INIT = 'heltti/auth/login/init';
export const LOGIN_SUCCESS = 'heltti/auth/login/success';
export const LOGIN_FAILURE = 'heltti/auth/login/failure';
export const LOGOUT = 'heltti/auth/logout';
export const AUTHENTICATION_INIT_SUCCESS = 'heltti/auth/authentication/init/success';
export const AUTHENTICATION_INIT_FAILURE = 'heltti/auth/authentication/init/failure';
export const AUTHENTICATION_REFRESH_SUCCESS = 'heltti/auth/authentication/refresh/success';
export const TERMS_NOT_ACCEPTED = 'heltti/auth/terms/notAccepted';
export const TERMS_ACCEPTED = 'heltti/auth/terms/accepted';

export type AuthState = {
    isLoggingIn: boolean;

    isAuthenticated?: boolean;
    isVerified: boolean;
    isTermsAccepted: boolean;

    error?: string;
};

function getInitialState() {
    return {
        isLoggingIn: false,

        isAuthenticated: undefined,
        isVerified: false,
        isTermsAccepted: false
    };
}

export function auth(state: AuthState = getInitialState(), action: AnyAction): AuthState {
    switch (action.type) {
        case LOGIN_INIT:
            return {
                ...state,
                isLoggingIn: true
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: true,
                isVerified: action.payload.verified,
                isTermsAccepted: action.payload.terms_accepted
            };

        case LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                isVerified: false,
                isTermsAccepted: false,
                error: action.payload.error
            };
        case LOGOUT:
            return {
                ...getInitialState(),
                isAuthenticated: false,
                isVerified: false,
                isTermsAccepted: false
            };

        case AUTHENTICATION_INIT_SUCCESS:
        case AUTHENTICATION_REFRESH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isVerified: action.payload.verified,
                isTermsAccepted: action.payload.terms_accepted
            };

        case AUTHENTICATION_INIT_FAILURE:
            return {
                ...state,
                isAuthenticated: false
            };

        case TERMS_ACCEPTED:
            return {
                ...state,
                isTermsAccepted: true
            };

        case TERMS_NOT_ACCEPTED:
            return {
                ...state,
                isTermsAccepted: false
            };

        default:
            return state;
    }
}

export function initAuthentication() {
    return (dispatch) => {
        return apiRefreshAuthentication()
            .then(({ success, result }) => {
                if (success) {
                    dispatch({ type: AUTHENTICATION_INIT_SUCCESS, payload: result });
                } else {
                    dispatch({ type: AUTHENTICATION_INIT_FAILURE });
                }
            })
            .catch(() => {
                dispatch({ type: AUTHENTICATION_INIT_FAILURE });
            });
    };
}

export function refreshAuthentication() {
    return (dispatch) => {
        return apiRefreshAuthentication()
            .then(({ success, result }) => {
                if (success) {
                    dispatch({ type: AUTHENTICATION_REFRESH_SUCCESS, payload: result });
                } else {
                    void logout()(dispatch);
                }
            })
            .catch(() => {
                void logout()(dispatch);
            });
    };
}

export function login(username: string, password: string) {
    return (dispatch) => {
        dispatch({ type: LOGIN_INIT });
        return authenticate(username, password)
            .then(({ result }) => {
                return dispatch({ type: LOGIN_SUCCESS, payload: result });
            })
            .catch((e) => {
                const error =
                    {
                        401: 'login.failed.credentials',
                        423: 'login.failed.expired',
                        429: 'login.failed.limit'
                    }[e.response?.status] ?? 'login.failed.credentials';

                dispatch({ type: LOGIN_FAILURE, payload: { error } });
                throw new Error();
            });
    };
}

export function logout() {
    return (dispatch) => {
        return apiLogout().then(() => dispatch({ type: LOGOUT }));
    };
}

export function acceptTerms(
    isMemberCommunicationAccepted: boolean,
    isMarketingAccepted: boolean,
    isFeedbackAccepted: boolean
) {
    return (dispatch) => {
        return apiAcceptTerms(isMemberCommunicationAccepted, isMarketingAccepted, isFeedbackAccepted).then(() => {
            dispatch({ type: TERMS_ACCEPTED });
        });
    };
}

export function eidentVerification(urlParams: string) {
    return (dispatch) => {
        return callEIdentVerification(urlParams)
            .then(({ result }) => {
                const { company_access } = result;
                if (company_access) {
                    dispatch({ type: LOGIN_SUCCESS, payload: result });
                } else {
                    dispatch({ type: LOGIN_FAILURE, payload: { error: 'login.failed.no_company_access' } });
                }
            })
            .catch(() => {
                dispatch({ type: LOGIN_FAILURE, payload: { error: 'login.failed.credentials' } });
            });
    };
}
