import React, { FunctionComponent } from 'react';
import { gql, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { Icon, Modal, Transition } from 'semantic-ui-react';
import { Error, Loader } from '@heltti/components';

import { companyInvoicingFragment } from '../data/fragments';
import {
    CompanyInvoicingEditMutation,
    CompanyInvoicingEditMutationVariables,
    CompanyInvoicingQuery,
    CompanyInvoicingQueryVariables
} from '../graphql-schema';
import { InvoicingForm, InvoicingFormData } from './InvoicingForm';
import { Translation, translations } from '../translations';
import { useLoadingQuery } from '../hooks/loading-query';

const QUERY_COMPANY_INVOICING = gql`
    query CompanyInvoicingQuery($companyId: ID!) {
        root {
            company(id: $companyId) {
                id
                invoicing {
                    ...CompanyInvoicingFragment
                }
            }

            finvoiceRouters {
                code
                name
            }
        }
    }

    ${companyInvoicingFragment}
`;

const MUTATION_COMPANY_INVOICING_EDIT = gql`
    mutation CompanyInvoicingEditMutation($invoicingData: CompanyInvoicingEditMutationInput!) {
        companyInvoicingEdit(input: $invoicingData) {
            companyInvoicing {
                ...CompanyInvoicingFragment
            }
        }
    }
    ${companyInvoicingFragment}
`;

type ModalProps = {
    close: () => void;
    companyId: string;
};

export const InvoicingModal: FunctionComponent<ModalProps> = (props) => {
    const { close, companyId } = props;

    const {
        data: companyData,
        loading: queryLoading,
        error: queryError
    } = useLoadingQuery<CompanyInvoicingQuery, CompanyInvoicingQueryVariables>(QUERY_COMPANY_INVOICING, {
        variables: { companyId }
    });

    const [companyInvoicingEdit, { loading: editMutationLoading, error: editMutationError }] = useMutation<
        CompanyInvoicingEditMutation,
        CompanyInvoicingEditMutationVariables
    >(MUTATION_COMPANY_INVOICING_EDIT, { refetchQueries: [QUERY_COMPANY_INVOICING] });

    if (queryLoading) {
        return <Loader />;
    }

    const error = queryError || editMutationError;

    if (error) {
        return <Error title={error.message} />;
    }

    // Finvoice router codes
    const finvoiceRouters = companyData?.root?.finvoiceRouters?.map((router) => router!) ?? [];

    // Invoicing data
    const invoicing = companyData?.root?.company?.invoicing;

    const initialValues: InvoicingFormData = {
        address1: invoicing?.address1,
        address2: invoicing?.address2,
        zip: invoicing?.zip,
        city: invoicing?.city,

        email: invoicing?.email,
        finvoiceAddress: invoicing?.finvoiceAddress,
        finvoiceRouterCode: invoicing?.finvoiceRouterCode?.replace('A_', ''),

        showMembersOnInvoiceAttachment: invoicing?.showMembersOnInvoiceAttachment,
        sendAttachmentWithInvoice: invoicing?.sendAttachmentWithInvoice
    };

    const onSubmit = async (values: InvoicingFormData) => {
        const invoicingData = {
            companyId,
            ...values
        };

        if (!invoicingData.finvoiceAddress) {
            delete invoicingData.finvoiceRouterCode;
        }

        if (!editMutationLoading) {
            try {
                await companyInvoicingEdit({ variables: { invoicingData } });
                close();
            } catch (error) {
                Sentry.captureMessage(`Insurance edit mutation failed: ${error}`, 'warning');
            }
        }
    };

    return (
        <Transition transitionOnMount unmountOnHide animation="fade" duration={200} visible>
            <Modal
                open
                noValidate
                closeIcon={<Icon as="i" className="close" name="times circle outline" />}
                closeOnDimmerClick={false}
                onClose={() => close()}
                centered={false}
                size="tiny"
            >
                <Modal.Header>
                    <Translation for={translations.invoicing.title} />
                </Modal.Header>

                <InvoicingForm
                    loading={editMutationLoading}
                    initialValues={initialValues}
                    finvoiceRouters={finvoiceRouters}
                    onSubmit={onSubmit}
                />
            </Modal>
        </Transition>
    );
};
