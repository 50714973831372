import React, { useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { format } from 'date-fns';

import { Card, Header, Icon, Image, List, Table } from 'semantic-ui-react';
import { Error, IconButton, Loader } from '@heltti/components';
import { getNodes } from '@heltti/common';

import { CompanyInsurancesListFragment, CompanyQuery, HelttiUserFragment } from '../../graphql-schema';
import { companyQuery } from '../../data/queries';
import { CompanyEditForm } from '../../components/CompanyEditForm';
import t, { Translation, translations } from '../../translations';
import { InsurancesModal } from '../../components/InsurancesModal';
import { InvoicingModal } from '../../components/InvoicingModal';
import { useLoadingQuery } from '../../hooks/loading-query';

type PersonItemProps = {
    isPrimary: boolean;
    person: HelttiUserFragment;
};

const PersonItem: React.FC<PersonItemProps> = (props) => {
    const { isPrimary, person } = props;
    const intl = useIntl();

    const primaryOrDeputy = isPrimary
        ? intl.formatMessage(t.staffUserIsPrimary)
        : intl.formatMessage(t.staffUserIsDeputy);
    const { firstName, lastName, email, avatarSmallUrl, title } = person;

    return (
        <Table.Row>
            <Table.Cell>
                <Header as="h4" className="noWrap">
                    <Image avatar src={avatarSmallUrl} size="huge" />

                    <Header.Content>
                        {firstName} {lastName}
                        <Header.Subheader>
                            {title}, {primaryOrDeputy.toLowerCase()}
                        </Header.Subheader>
                        <Header.Subheader>
                            <a href={`mailto: ${email}`}>{email}</a>
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            </Table.Cell>
        </Table.Row>
    );
};

type InsuranceListItemProps = {
    insurance: CompanyInsurancesListFragment;
};

const InsuranceListItem: React.FC<InsuranceListItemProps> = (props) => {
    const intl = useIntl();
    const { type, name: insuranceName, insuranceCompany } = props.insurance;
    const { name: insuranceCompanyName } = insuranceCompany ?? {};

    return (
        <Table.Row>
            <Table.Cell>
                <Header.Subheader>
                    {insuranceName?.length
                        ? insuranceName
                        : intl.formatMessage({ id: `company.insurances.type.${type}` })}
                </Header.Subheader>
            </Table.Cell>
            <Table.Cell>
                <Header.Subheader>{insuranceCompanyName}</Header.Subheader>
            </Table.Cell>
        </Table.Row>
    );
};

type CompanyInfoProps = {
    companyId: string;
};

export const CompanyInfo: React.FC<CompanyInfoProps> = (props) => {
    const [companyInfoEditOpen, setCompanyInfoEditOpen] = useState<boolean>(false);
    const [insurancesEditOpen, setInsurancesEditOpen] = useState<boolean>(false);
    const [invoicingEditOpen, setInvoicingEditOpen] = useState<boolean>(false);

    const { data, loading, error } = useLoadingQuery<CompanyQuery>(companyQuery, {
        variables: { companyId: props.companyId }
    });

    const intl = useIntl();

    const company = data?.root?.company;

    if (loading || !company) {
        return <Loader />;
    }

    if (error) {
        return <Error />;
    }

    const {
        id,
        name,
        businessId,
        address1,
        address2,
        city,
        zip,
        contractPrice,
        contractPriceMobile,
        contractMinimumBilling,
        contractNewMemberFee,
        staff,
        invoicing,
        membersCanReportSickLeave,
        sickLeaveMaxDays,
        sickLeavePractice,
        representatives
    } = company;

    const insurances = getNodes(company.insurances);
    const fiscalYearEvents = getNodes(data?.root?.company?.calendar?.fiscalYearEvents);
    let { start: fiscalYearStart, end: fiscalYearEnd } = fiscalYearEvents?.[0] ?? {};

    if (fiscalYearStart && fiscalYearEnd) {
        fiscalYearStart = format(new Date(fiscalYearStart), 'd.M.yyyy');
        fiscalYearEnd = format(new Date(fiscalYearEnd), 'd.M.yyyy');
    }

    return (
        <Card.Group doubling itemsPerRow={2}>
            <Card>
                <Card.Content>
                    {companyInfoEditOpen && (
                        <CompanyEditForm open companyId={id} close={() => setCompanyInfoEditOpen(false)} />
                    )}

                    <Header floated="left" as="h4">
                        {intl.formatMessage(t.companyInfo)}
                    </Header>

                    <IconButton floated="right" name="edit outline" onClick={() => setCompanyInfoEditOpen(true)} />

                    <Table basic="very">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.companyName)}</Header>
                                </Table.Cell>

                                <Table.Cell>
                                    <Header.Subheader>{name}</Header.Subheader>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.companyId)}</Header>
                                </Table.Cell>

                                <Table.Cell>
                                    <Header.Subheader>{businessId}</Header.Subheader>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.companyAddress)}</Header>
                                </Table.Cell>

                                <Table.Cell>
                                    <Header.Subheader>{address1}</Header.Subheader>
                                    <Header.Subheader>{address2}</Header.Subheader>
                                    <Header.Subheader>
                                        {zip} {city}
                                    </Header.Subheader>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.companyFiscalYear)}</Header>
                                </Table.Cell>

                                <Table.Cell>
                                    <Header.Subheader>
                                        {fiscalYearStart} - {fiscalYearEnd}
                                    </Header.Subheader>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.sickLeavePracticeTitle)}</Header>
                                </Table.Cell>

                                <Table.Cell>
                                    <Header.Subheader>
                                        {membersCanReportSickLeave && (
                                            <>
                                                {intl.formatMessage(t.sickLeavePracticeSelfReportedMaxDays)}
                                                {': '}
                                                {sickLeaveMaxDays}
                                                <br />
                                            </>
                                        )}
                                        {sickLeavePractice}
                                    </Header.Subheader>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content>
                    <Header as="h4">{intl.formatMessage(t.companyContactPersons)}</Header>

                    <Table basic="very">
                        <Table.Body>
                            {representatives?.map((representative) => (
                                <Table.Row key={representative.id}>
                                    <Table.Cell>
                                        {representative.firstName} {representative.lastName}
                                        <br />
                                        {representative.description}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Header.Subheader>
                                            <Icon name="phone" color="grey" />{' '}
                                            {representative.phone && (
                                                <a href={`tel:${representative.phone}`}>{representative.phone}</a>
                                            )}
                                        </Header.Subheader>
                                        <Header.Subheader>
                                            <Icon name="mail" color="grey" />{' '}
                                            {representative.email && (
                                                <a href={`mailto:${representative.email}`}>{representative.email}</a>
                                            )}
                                        </Header.Subheader>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content>
                    <Header as="h4">{intl.formatMessage(t.companyContract)}</Header>

                    <Table basic="very">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.companyContractPrice)}</Header>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header.Subheader>
                                        {contractPrice && <FormattedNumber value={contractPrice} />}€
                                    </Header.Subheader>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.companyContractPriceMobile)}</Header>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header.Subheader>
                                        {contractPriceMobile && <FormattedNumber value={contractPriceMobile} />}€
                                    </Header.Subheader>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.companyContractMinimumBilling)}</Header>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header.Subheader>
                                        {contractMinimumBilling && <FormattedNumber value={contractMinimumBilling} />}€
                                    </Header.Subheader>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(t.companyContractNewMemberFee)}</Header>
                                </Table.Cell>
                                <Table.Cell>
                                    <List.Icon
                                        name={contractNewMemberFee ? 'check' : 'remove'}
                                        color={contractNewMemberFee ? 'green' : 'red'}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content>
                    <Header as="h4">{intl.formatMessage(t.companyStaff)}</Header>

                    <Table basic="very">
                        <Table.Body>
                            {getNodes(staff).map(
                                (staffer) =>
                                    !!staffer.user && (
                                        <PersonItem
                                            key={staffer.user?.id}
                                            person={staffer.user}
                                            isPrimary={staffer.isPrimary === true}
                                        />
                                    )
                            )}
                        </Table.Body>
                    </Table>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content>
                    {insurancesEditOpen ? (
                        <InsurancesModal companyId={id} close={() => setInsurancesEditOpen(false)} />
                    ) : null}

                    <Header floated="left" as="h4">
                        {intl.formatMessage({ id: 'company.insurances' })}
                    </Header>

                    <IconButton floated="right" name="edit outline" onClick={() => setInsurancesEditOpen(true)} />
                    <Table basic="very">
                        <Table.Body>
                            {insurances.map((insurance) => (
                                <InsuranceListItem key={insurance.id} insurance={insurance} />
                            ))}

                            {!insurances.length && (
                                <Table.Row>
                                    <Table.Cell colSpan="2">
                                        <Header.Subheader>
                                            <FormattedMessage id="company.insurances.none" />
                                        </Header.Subheader>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content>
                    {invoicingEditOpen && <InvoicingModal companyId={id} close={() => setInvoicingEditOpen(false)} />}

                    <Header floated="left" as="h4">
                        {intl.formatMessage(translations.invoicing.title)}
                    </Header>

                    <IconButton floated="right" name="edit outline" onClick={() => setInvoicingEditOpen(true)} />

                    <Table basic="very">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">{intl.formatMessage(translations.invoicing.address)}</Header>
                                </Table.Cell>

                                <Table.Cell>
                                    {!!invoicing && (
                                        <>
                                            <Header.Subheader>{invoicing.address1}</Header.Subheader>
                                            <Header.Subheader>{invoicing.address2}</Header.Subheader>
                                            <Header.Subheader>
                                                {invoicing.zip} {invoicing.city}
                                            </Header.Subheader>
                                        </>
                                    )}
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">
                                        <Translation for={translations.invoicing.electronicInvoicing} />
                                    </Header>
                                </Table.Cell>

                                <Table.Cell>
                                    {!!invoicing && (
                                        <>
                                            {!!invoicing.email && (
                                                <Header.Subheader>{invoicing.email}</Header.Subheader>
                                            )}
                                            {!!invoicing.finvoiceAddress && (
                                                <Header.Subheader>{invoicing.finvoiceAddress}</Header.Subheader>
                                            )}
                                            {!!invoicing.finvoiceRouterName && (
                                                <Header.Subheader>{invoicing.finvoiceRouterName}</Header.Subheader>
                                            )}
                                        </>
                                    )}
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h5">
                                        <Translation for={translations.invoicing.sendAttachmentWithInvoice} />
                                    </Header>
                                </Table.Cell>

                                <Table.Cell>
                                    {!!invoicing && (
                                        <Header.Subheader>
                                            <Translation
                                                for={
                                                    invoicing.sendAttachmentWithInvoice
                                                        ? translations.yesNo.yes
                                                        : translations.yesNo.no
                                                }
                                            />
                                        </Header.Subheader>
                                    )}
                                </Table.Cell>
                            </Table.Row>

                            {!!invoicing && invoicing.sendAttachmentWithInvoice && (
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as="h5">
                                            <Translation for={translations.invoicing.showMembersOnInvoiceAttachment} />
                                        </Header>
                                    </Table.Cell>

                                    <Table.Cell>
                                        <Header.Subheader>
                                            <Translation
                                                for={
                                                    invoicing.showMembersOnInvoiceAttachment
                                                        ? translations.yesNo.yes
                                                        : translations.yesNo.no
                                                }
                                            />
                                        </Header.Subheader>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Card.Content>
            </Card>
        </Card.Group>
    );
};
