import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { isBefore } from 'date-fns';

import { Form, Header, Modal, Transition } from 'semantic-ui-react';
import { Button, DateRangePicker } from '@heltti/components';

import t from '../translations';

export interface CompanyFormProps {
    open?: boolean;
    loading?: boolean;
    initialValues: CompanyFormData;
    onSubmit: (values: CompanyFormData) => Promise<void>;
    onCancel: () => Promise<void>;
}

export type CompanyFormData = {
    fiscalStartDate: string | null;
    fiscalEndDate: string | null;
    membersCanReportSickLeave: boolean;
    sickLeaveMaxDays: number;
    sickLeavePractice: string;
};

const schema = (intl: IntlShape) => {
    const required = intl.formatMessage({ id: 'form.validate.required' });

    return yup.object().shape({
        fiscalStartDate: yup.string().required(required),
        fiscalEndDate: yup.string().required(required),
        membersCanReportSickLeave: yup.boolean().required(required),
        sickLeaveMaxDays: yup.number().when('membersCanReportSickLeave', {
            is: true,
            then: yup.number().required(required),
            otherwise: yup.number()
        }),
        sickLeavePractice: yup.string()
    });
};

export const CompanyForm: React.FC<CompanyFormProps> = (props) => {
    const intl = useIntl();
    const { initialValues, loading, open, onSubmit, onCancel } = props;

    const { handleChange, handleSubmit, values, errors, isValid, setFieldValue } = useFormik<CompanyFormData>({
        initialValues,
        onSubmit,
        validationSchema: schema(intl)
    });

    const renderFiscalYearFields = () => {
        const { fiscalStartDate, fiscalEndDate } = values;
        const start = fiscalStartDate ? new Date(fiscalStartDate) : undefined;
        const end = fiscalEndDate ? new Date(fiscalEndDate) : undefined;
        return (
            <>
                <Header as="h4">
                    <FormattedMessage id="form.company.basic_info" />
                </Header>
                <Form.Group width="equal">
                    <Form.Field
                        required
                        disabled={!end || isBefore(end, new Date())}
                        control={DateRangePicker}
                        initialRange={[start, end]}
                        onRangeChange={([start, end]: [Date, Date]) => {
                            void setFieldValue('fiscalStartDate', start.toISOString());
                            void setFieldValue('fiscalEndDate', end.toISOString());
                        }}
                        placeholders={[
                            intl.formatMessage({ id: 'form.company.basic_info.fiscal.start_date' }),
                            intl.formatMessage({ id: 'form.company.basic_info.fiscal.end_date' })
                        ]}
                        label={intl.formatMessage({ id: 'form.company.basic_info.fiscal' })}
                        error={
                            !fiscalStartDate || !!errors?.fiscalStartDate || !fiscalEndDate || !!errors?.fiscalEndDate
                        }
                    />
                </Form.Group>
            </>
        );
    };

    const renderSickLeavePracticeFields = () => {
        const { membersCanReportSickLeave, sickLeaveMaxDays, sickLeavePractice } = values;
        return (
            <>
                <Header as="h4">
                    <FormattedMessage id="sickLeavePractice.title" />
                </Header>
                <Form.Group inline>
                    <Form.Checkbox
                        id="membersCanReportSickLeave"
                        name="membersCanReportSickLeave"
                        onChange={handleChange}
                        label={intl.formatMessage(t.sickLeavePracticeSelfReportedMaxDays)}
                        checked={membersCanReportSickLeave}
                    />
                    <Form.Field>
                        <Form.Input
                            required={membersCanReportSickLeave}
                            disabled={!membersCanReportSickLeave}
                            id="sickLeaveMaxDays"
                            name="sickLeaveMaxDays"
                            value={membersCanReportSickLeave ? sickLeaveMaxDays : ''}
                            onChange={handleChange}
                            error={!!errors?.sickLeaveMaxDays}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            id="sickLeavePractice"
                            name="sickLeavePractice"
                            value={sickLeavePractice}
                            onChange={handleChange}
                            label={intl.formatMessage(t.sickLeavePracticeDescription)}
                            error={!!errors?.sickLeavePractice}
                        />
                    </Form.Field>
                </Form.Group>
            </>
        );
    };

    return (
        <Transition transitionOnMount unmountOnHide animation="fade" duration={200} visible={open}>
            <Modal
                open
                noValidate
                as={Form}
                onSubmit={handleSubmit}
                loading={false}
                error={!isValid}
                onClose={() => close()}
                centered={false}
            >
                <Modal.Header>
                    <FormattedMessage id="form.company.title.edit" />
                </Modal.Header>
                <Modal.Content>
                    {renderFiscalYearFields()}
                    {renderSickLeavePracticeFields()}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        onClick={(e) => {
                            e.preventDefault();
                            void onCancel();
                        }}
                    >
                        <FormattedMessage id="form.company.cancel" />
                    </Button>
                    <Button primary formNoValidate type="submit" disabled={!isValid} loading={loading}>
                        <FormattedMessage id="form.company.save" />
                    </Button>
                </Modal.Actions>
            </Modal>
        </Transition>
    );
};
