import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import { gql } from '@apollo/client';
import { Table } from 'semantic-ui-react';
import { isFuture } from 'date-fns';

import { Error, Loader } from '@heltti/components';
import t from '../translations';
import { GetCompanyMembersQuery, ListEmploymentFragment } from '../graphql-schema';
import { EmploymentEditForm } from './EmploymentEditForm';
import { getNodes } from '@heltti/common';
import { listEmploymentFragment } from '../data/fragments';
import { useInfiniteLoadingQuery } from '../hooks/infinite-loading-query';

export const GET_COMPANY_MEMBERS = 'GetCompanyMembers';

export const QUERY_GET_COMPANY_MEMBERS = gql`
    query GetCompanyMembers(
        $companyId: ID!
        $orderBy: String
        $descending: Boolean
        $query: String
        $first: Int
        $after: String
    ) {
        root {
            company(id: $companyId) {
                id

                employments(first: $first, after: $after, orderBy: $orderBy, descending: $descending, query: $query) {
                    edges {
                        node {
                            ...ListEmploymentFragment
                        }
                    }

                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
            }
        }
    }

    ${listEmploymentFragment}
`;

enum SortableColumn {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    LOCATION = 'location',
    DEPARTMENT = 'department'
}

type SortOrder = { [key in SortableColumn]?: 'ascending' | 'descending' };

export interface EmploymentListProps {
    editable: boolean;
    companyId: string;
    query: string;
}

const orderByDesc = (sortOrder: SortOrder): [string, boolean | undefined] => {
    const [[column, order]] = Object.entries(sortOrder);
    return [column, order === 'descending' || undefined];
};

const employmentIsValidOrStarting = (employment: ListEmploymentFragment): boolean => {
    return employment.endDate ? isFuture(new Date(employment.endDate)) : true;
};

export const EmploymentList: React.FC<EmploymentListProps> = (props) => {
    const { formatMessage } = useIntl();
    const { editable, companyId, query } = props;
    const history = useHistory();
    const [sortOrder, setSortOrder] = useState<SortOrder>({ [SortableColumn.LAST_NAME]: 'ascending' });
    const [orderBy, descending] = orderByDesc(sortOrder);
    const [editEmploymentId, setEditEmploymentId] = useState<string | undefined>(undefined);

    const { data, loading, error, sentryRef } = useInfiniteLoadingQuery<GetCompanyMembersQuery>(
        QUERY_GET_COMPANY_MEMBERS,
        `root.company.employments`,
        {
            first: 30,
            orderBy,
            descending,
            query
        },
        { variables: { companyId } }
    );
    const hasNextPage = data?.root?.company?.employments?.pageInfo.hasNextPage;
    const employments = getNodes(data?.root?.company?.employments).filter(employmentIsValidOrStarting);

    const openEmployment = useCallback(
        (employment: ListEmploymentFragment) => {
            setEditEmploymentId(employment.id);
        },
        [companyId, history]
    );

    const onColumnSortOrderChanged = useCallback(
        (column: SortableColumn) => {
            if (sortOrder[column] === 'ascending') {
                setSortOrder({ [column]: 'descending' });
            } else {
                setSortOrder({ [column]: 'ascending' });
            }
        },
        [sortOrder, setSortOrder]
    );

    return (
        <div>
            {error && <Error title={error.name} description={error.message} />}

            {editable && editEmploymentId ? (
                <EmploymentEditForm employmentId={editEmploymentId} close={() => setEditEmploymentId(undefined)} />
            ) : null}

            <Table selectable={editable} sortable celled compact singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={sortOrder[SortableColumn.LAST_NAME]}
                            onClick={() => onColumnSortOrderChanged(SortableColumn.LAST_NAME)}
                        >
                            {formatMessage(t.profileLastName)}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width="three"
                            sorted={sortOrder[SortableColumn.FIRST_NAME]}
                            onClick={() => onColumnSortOrderChanged(SortableColumn.FIRST_NAME)}
                        >
                            {formatMessage(t.profileFirstName)}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width="three"
                            sorted={sortOrder[SortableColumn.START_DATE]}
                            onClick={() => onColumnSortOrderChanged(SortableColumn.START_DATE)}
                        >
                            {formatMessage(t.employmentStart)}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width="three"
                            sorted={sortOrder[SortableColumn.END_DATE]}
                            onClick={() => onColumnSortOrderChanged(SortableColumn.END_DATE)}
                        >
                            {formatMessage(t.employmentEnd)}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width="three"
                            sorted={sortOrder[SortableColumn.LOCATION]}
                            onClick={() => onColumnSortOrderChanged(SortableColumn.LOCATION)}
                        >
                            {formatMessage(t.employmentLocation)}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width="three"
                            sorted={sortOrder[SortableColumn.DEPARTMENT]}
                            onClick={() => onColumnSortOrderChanged(SortableColumn.DEPARTMENT)}
                        >
                            {formatMessage(t.employmentDepartment)}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {employments.map((employment) => (
                        <EmploymentRow
                            key={employment.id}
                            employment={employment}
                            onClick={() => openEmployment(employment)}
                        />
                    ))}

                    {(hasNextPage || loading) && (
                        <Table.Row>
                            <Table.Cell colSpan={5} textAlign="center">
                                {/* Sentry component, see https://www.npmjs.com/package/react-infinite-scroll-hook */}
                                <div ref={sentryRef}>
                                    <Loader inline />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )}

                    {!employments?.length ? (
                        <Table.Row>
                            <Table.Cell colSpan="3">
                                {query?.length
                                    ? formatMessage(t.employmentSearchNoResults)
                                    : formatMessage(t.employmentNone)}
                            </Table.Cell>
                        </Table.Row>
                    ) : null}
                </Table.Body>
            </Table>
        </div>
    );
};

type EmploymentRowProps = {
    employment: ListEmploymentFragment;
    onClick: () => void;
};

const EmploymentRow: React.FC<EmploymentRowProps> = (props) => {
    const { employment, onClick } = props;

    if (!employmentIsValidOrStarting(employment)) {
        return null;
    }

    return (
        <Table.Row onClick={onClick}>
            <Table.Cell>{employment.member.lastName}</Table.Cell>
            <Table.Cell>{employment.member.firstName}</Table.Cell>
            <Table.Cell>
                <FormattedDate value={employment.startDate} />
            </Table.Cell>
            <Table.Cell warning={!!employment.endDate}>
                {employment.endDate ? <FormattedDate value={employment.endDate} /> : '-'}
            </Table.Cell>
            <Table.Cell>{employment.location ?? '-'}</Table.Cell>
            <Table.Cell>{employment.department?.name ?? '-'}</Table.Cell>
        </Table.Row>
    );
};
