import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router';

import { Header } from 'semantic-ui-react';
import { Content } from '@heltti/components';

import { CompanyInfo } from './CompanyInfo';
import { AppNavigationBar } from '../../components/AppNavigationBar';
import { AppPrivateRouteParams } from '../../components/App';

import { useUserProfile } from '../../context/UserProfile';

import './home.less';

export const HomePage: React.FC = () => {
    const intl = useIntl();
    const { firstName, companyAccess } = useUserProfile()!;
    const { companyId } = useParams<AppPrivateRouteParams>();

    return companyId in companyAccess ? (
        <div id="app">
            <AppNavigationBar />

            <div id="contentScroll">
                <div id="home">
                    <Content id="home-content">
                        <Header as="h3" style={{ paddingLeft: '20px' }}>
                            {intl.formatMessage({ id: 'hi' }, { name: firstName })}
                        </Header>

                        <CompanyInfo companyId={companyId} />
                    </Content>
                </div>
            </div>
        </div>
    ) : (
        <Redirect to="/" />
    );
};
