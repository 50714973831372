import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';

import { AppTheme, AppThemeProvider } from '@heltti/components';

import { createStore } from './reduxSetup';
import { config } from './config';
import { setupWithAuthentication as setupApolloWithAuthentication } from './apollo';
import { reducers } from './ducks';
import { App } from './components/App';
import { AppLanguageProvider } from './context/AppLanguage';

declare const __ENV__: 'staging' | 'production' | 'development';
declare const __VERSION__: string;

const reduxStore = createStore(reducers, []);
const apolloClient = setupApolloWithAuthentication(reduxStore);

if (__ENV__ !== 'development') {
    Sentry.init({
        dsn: config?.sentry?.dsn,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.05,
        release: `Customer web - ${__VERSION__}`,
        environment: __ENV__
    });
}

const RootComponent: React.FC = () => {
    return (
        <AppThemeProvider value={AppTheme.BLUE}>
            <Provider store={reduxStore}>
                <ApolloProvider client={apolloClient}>
                    <AppLanguageProvider>
                        <App />
                    </AppLanguageProvider>
                </ApolloProvider>
            </Provider>
        </AppThemeProvider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('app-container')!);

root.render(<RootComponent />);
