import React, { useState } from 'react';
import { useDebounce } from '@react-hook/debounce';
import { Redirect, useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { Card, Form, Header, Icon, Input, Modal, Transition } from 'semantic-ui-react';
import { Button, Content } from '@heltti/components';

import { useUserProfile } from '../../context/UserProfile';
import { AppPrivateRouteParams } from '../../components/App';
import { AppNavigationBar } from '../../components/AppNavigationBar';
import { DocumentList } from '../../components/DocumentList';
import { DocumentUpload } from '../../components/DocumentUpload';
import t from '../../translations';

import './documents.less';

export const DocumentsPage: React.FC = () => {
    const intl = useIntl();

    const { companyAccess } = useUserProfile()!;
    const { companyId } = useParams<AppPrivateRouteParams>();

    const [query, setQuery] = useDebounce<string>('', 200);
    const [open, setOpen] = useState<boolean>(false);

    if (!(companyId in companyAccess)) {
        return <Redirect to="/" />;
    }

    return (
        <div id="app">
            <AppNavigationBar />
            <div id="contentScroll">
                <div id="documents">
                    <Content id="documents" containerWidth="wide">
                        <Header as="h3" style={{ paddingLeft: '20px' }}>
                            {intl.formatMessage(t.documentsTitle)}
                        </Header>
                        <Card fluid>
                            <Card.Content>
                                <Form>
                                    <Form.Group>
                                        <Form.Field width="4">
                                            <Input
                                                icon={{ name: 'search', link: true }}
                                                placeholder={intl.formatMessage(t.documentsSearch)}
                                                onChange={(_, { value }) => setQuery(value)}
                                            />
                                        </Form.Field>
                                        <Form.Field width="12">
                                            <Button floated="right" onClick={() => setOpen(true)}>
                                                <Icon name="plus circle" />
                                                {intl.formatMessage(t.documentAdd)}
                                            </Button>
                                            <Transition
                                                transitionOnMount
                                                unmountOnHide
                                                animation="fade"
                                                duration={200}
                                                visible={open}
                                            >
                                                <Modal open onClose={() => setOpen(false)} centered={false}>
                                                    <Modal.Header>{intl.formatMessage(t.documentAdd)}</Modal.Header>
                                                    <Modal.Content>
                                                        <DocumentUpload
                                                            companyId={companyId}
                                                            close={() => setOpen(false)}
                                                        />
                                                    </Modal.Content>
                                                </Modal>
                                            </Transition>
                                        </Form.Field>
                                    </Form.Group>
                                    <DocumentList companyId={companyId} query={query} />
                                </Form>
                            </Card.Content>
                        </Card>
                    </Content>
                </div>
            </div>
        </div>
    );
};
