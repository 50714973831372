import React from 'react';
import { connect } from 'react-redux';

import { NotVerified } from '@heltti/components';

import { getUrlForEident } from '../../api';
import { GetVerificationUrlFunction } from '@heltti/components/dist/components/Login/VerifyButton';

type PlainNotVerifiedPageProps = {
    getVerificationUrl: GetVerificationUrlFunction;
};

const PlainNotVerifiedPage: React.FC<PlainNotVerifiedPageProps> = (props) => {
    const { getVerificationUrl } = props;

    return (
        <div id="app">
            <div id="contentScroll">
                <NotVerified getVerificationUrl={getVerificationUrl} />
            </div>
        </div>
    );
};

export const NotVerifiedPage = connect(() => ({
    getVerificationUrl: getUrlForEident
}))(PlainNotVerifiedPage);
