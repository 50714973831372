import React from 'react';
import { useIntl } from 'react-intl';

import { Grid, Icon, Message } from 'semantic-ui-react';
import { Content } from '@heltti/components';

import t from './../../translations';

export const NotFound404: React.FC = () => {
    const intl = useIntl();

    return (
        <Content id="not-found" containerWidth="narrow">
            <Grid centered style={{ height: '100%' }}>
                <Grid.Row columns={1} verticalAlign="middle">
                    <Grid.Column>
                        <Message icon warning>
                            <Icon name="umbrella" loading />

                            <Message.Header>{intl.formatMessage(t.errorNotFoundTitle)}</Message.Header>

                            <Message.Content>{intl.formatMessage(t.errorNotFoundDescription)}</Message.Content>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Content>
    );
};
