import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';

import { Header, Icon, Menu, Modal, Tab, Transition } from 'semantic-ui-react';
import { Error, Loader } from '@heltti/components';

import { InsuranceForm, InsuranceFormData } from './InsuranceForm';
import { companyInsurancesFullFragment, insuranceCompanyListFragment, locationListFragment } from '../data/fragments';
import {
    CompanyInsuranceAddMutation,
    CompanyInsuranceAddMutationVariables,
    CompanyInsuranceEditMutation,
    CompanyInsuranceEditMutationVariables,
    CompanyInsurancesFullFragment,
    GetCompanyInsurancesQuery,
    GetCompanyInsurancesQueryVariables
} from '../graphql-schema';
import { flatDiff, getNodes } from '@heltti/common';
import { useLoadingQuery } from '../hooks/loading-query';

const QUERY_NAME_GET_COMPANY_INSURANCES = 'GetCompanyInsurances';

const QUERY_GET_COMPANY_INSURANCES = gql`
    query GetCompanyInsurances($companyId: ID!) {
        root {
            company(id: $companyId) {
                id
                insurances {
                    edges {
                        node {
                            ...CompanyInsurancesFullFragment
                        }
                    }
                }
            }
            insuranceCompanies {
                edges {
                    node {
                        ...InsuranceCompanyListFragment
                    }
                }
            }
            locations {
                edges {
                    node {
                        ...LocationListFragment
                    }
                }
            }
        }
    }
    ${companyInsurancesFullFragment}
    ${insuranceCompanyListFragment}
    ${locationListFragment}
`;

const MUTATION_COMPANY_INSURANCE_EDIT = gql`
    mutation CompanyInsuranceEdit($insuranceData: CompanyInsuranceEditMutationInput!) {
        companyInsuranceEdit(input: $insuranceData) {
            companyInsurance {
                ...CompanyInsurancesFullFragment
            }
        }
    }
    ${companyInsurancesFullFragment}
`;

const MUTATION_COMPANY_INSURANCE_ADD = gql`
    mutation CompanyInsuranceAdd($insuranceData: CompanyInsuranceAddMutationInput!) {
        companyInsuranceAdd(input: $insuranceData) {
            companyInsurance {
                ...CompanyInsurancesFullFragment
            }
        }
    }
    ${companyInsurancesFullFragment}
`;

export type InsurancesModalProps = {
    close: () => void;
    companyId: string;
};

export const InsurancesModal: React.FC<InsurancesModalProps> = (props) => {
    const intl = useIntl();
    const { close, companyId } = props;

    const {
        data,
        loading: queryLoading,
        error: queryError
    } = useLoadingQuery<GetCompanyInsurancesQuery, GetCompanyInsurancesQueryVariables>(QUERY_GET_COMPANY_INSURANCES, {
        variables: { companyId }
    });

    const [companyInsuranceEdit, { loading: editMutationLoading, error: editMutationError }] = useMutation<
        CompanyInsuranceEditMutation,
        CompanyInsuranceEditMutationVariables
    >(MUTATION_COMPANY_INSURANCE_EDIT);

    const [companyInsuranceAdd, { loading: addMutationLoading, error: addMutationError }] = useMutation<
        CompanyInsuranceAddMutation,
        CompanyInsuranceAddMutationVariables
    >(MUTATION_COMPANY_INSURANCE_ADD, { refetchQueries: [QUERY_NAME_GET_COMPANY_INSURANCES] });

    const insurances = getNodes(data?.root?.company?.insurances);
    const insuranceCompanies = getNodes(data?.root?.insuranceCompanies).sort((a, b) => a.name.localeCompare(b.name));
    const locations = getNodes(data?.root?.locations).sort((a, b) => a.name.localeCompare(b.name));

    if (queryLoading) {
        return <Loader />;
    }

    const error = queryError || addMutationError || editMutationError;
    if (error) {
        return <Error title={error.message} />;
    }

    const renderEditInsuranceForm = (companyInsurance: CompanyInsurancesFullFragment) => {
        const {
            id: companyInsuranceId,
            type,
            name,
            number,
            excess,
            instructionsMember,
            validFrom,
            validUntil,
            insuranceCompany,
            preferredLocation
        } = companyInsurance;

        const initialValues: InsuranceFormData = {
            type,
            name: name ?? undefined,
            number: number ?? undefined,
            excess,
            instructionsMember: instructionsMember ?? undefined,
            validFrom: validFrom ? new Date(validFrom) : undefined,
            validUntil: validUntil ? new Date(validUntil) : undefined,
            insuranceCompanyId: insuranceCompany?.id,
            preferredLocationId: preferredLocation?.id
        };

        const onSubmit = async (values: InsuranceFormData) => {
            const dateFormat = (date?: Date) => (date ? format(date, 'yyyy-MM-dd') : undefined);
            const insuranceData = {
                companyInsuranceId,
                ...flatDiff(initialValues, values),
                validFrom: dateFormat(values.validFrom),
                validUntil: dateFormat(values.validUntil)
            };
            if (!editMutationLoading) {
                try {
                    await companyInsuranceEdit({ variables: { insuranceData } });
                } catch (error) {
                    Sentry.captureMessage(`Insurance edit mutation failed: ${error}`, 'warning');
                }
            }
        };

        return (
            <Tab.Pane>
                <InsuranceForm
                    mode="edit"
                    loading={editMutationLoading}
                    initialValues={initialValues}
                    insuranceCompanies={insuranceCompanies}
                    locations={locations}
                    onSubmit={onSubmit}
                />
            </Tab.Pane>
        );
    };

    const renderEditInsurancePane = (companyInsurance: CompanyInsurancesFullFragment) => {
        const { id: insuranceId, type, name, insuranceCompany } = companyInsurance;

        const typeName = intl.formatMessage({ id: `company.insurances.type.${type}` });
        const insuranceCompanyName = insuranceCompanies?.find(({ id }) => id === insuranceCompany?.id)?.name;

        return {
            menuItem: (
                <Menu.Item key={`edit-${insuranceId}`} name={insuranceId}>
                    <Header as="h4" style={{ marginBottom: '4px' }}>
                        {insuranceCompanyName}
                    </Header>
                    <Header as="h5" style={{ marginTop: '4px' }}>
                        {name?.length ? name : typeName}
                    </Header>
                </Menu.Item>
            ),
            render: () => renderEditInsuranceForm(companyInsurance)
        };
    };

    const renderAddInsuranceForm = () => {
        const onSubmit = async (values: InsuranceFormData) => {
            const dateFormat = (date?: Date) => (date ? format(date, 'yyyy-MM-dd') : undefined);
            const insuranceData = {
                companyId,
                ...values,
                type: values.type!,
                excess: values.excess!,
                validFrom: dateFormat(values.validFrom),
                validUntil: dateFormat(values.validUntil),
                insuranceCompanyId: values.insuranceCompanyId!,
                preferredLocationId: values.preferredLocationId!
            };
            if (!editMutationLoading) {
                try {
                    await companyInsuranceAdd({ variables: { insuranceData } });
                } catch (error) {
                    Sentry.captureMessage(`Insurance add mutation failed: ${error}`, 'warning');
                }
            }
        };

        return (
            <Tab.Pane>
                <InsuranceForm
                    mode="create"
                    loading={addMutationLoading}
                    initialValues={{}}
                    insuranceCompanies={insuranceCompanies}
                    locations={locations}
                    onSubmit={onSubmit}
                />
            </Tab.Pane>
        );
    };

    const renderAddInsurancePane = () => {
        return {
            menuItem: (
                <Menu.Item key="add" name="add">
                    <Header as="h4">
                        <div>
                            <Icon name="plus circle" />
                            <FormattedMessage id="form.insurance.title.create" />
                        </div>
                    </Header>
                </Menu.Item>
            ),
            render: () => renderAddInsuranceForm()
        };
    };

    return (
        <Transition transitionOnMount unmountOnHide animation="fade" duration={200} visible>
            <Modal
                open
                noValidate
                closeIcon={<Icon as="i" className="close" name="times circle outline" />}
                closeOnDimmerClick={false}
                onClose={() => close()}
                centered={false}
            >
                <Modal.Header>
                    <FormattedMessage id={`form.insurances.title`} />
                </Modal.Header>
                <Modal.Content>
                    <Tab
                        menu={{ pointing: true, attached: false, vertical: true, fluid: false }}
                        panes={[...insurances.map(renderEditInsurancePane), renderAddInsurancePane()]}
                    />
                </Modal.Content>
            </Modal>
        </Transition>
    );
};
