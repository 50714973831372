import t from '../translations';

export const billingCategoryOptions = {
    A_1: t.employmentCategoryFullTime,
    A_2: t.employmentCategoryPartTime,
    A_3: t.employmentCategoryIncident,
    A_4: t.employmentCategoryIncidentKela2,
    A_5: t.employmentCategoryMobile
};

export type Languages = {
    [id: number]: { shortName: string; name: string };
};

export const languages = {
    1: { shortName: 'fi', name: 'suomi' },
    3: { shortName: 'en', name: 'English' }
};
