import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import * as Sentry from '@sentry/react';
import { FormattedMessage } from 'react-intl';

import { Divider, Form, Icon, Input, Message, Table } from 'semantic-ui-react';
import { Button } from '@heltti/components';

import { DocumentAddMutation, DocumentAddMutationVariables } from '../graphql-schema';

import { GET_COMPANY_DOCUMENTS } from './DocumentList';
import { fileExtensionToIconName } from '../utils/icons';

export const MUTATION_ADD_DOCUMENT = gql`
    mutation DocumentAddMutation($data: DocumentAddMutationInput!) {
        documentAdd(input: $data) {
            clientMutationId
            document {
                name
            }
        }
    }
`;

type Metadata = {
    [key: string]: { description: string; category: string };
};

export interface DocumentUploadProps {
    companyId: string;
    close: () => void;
}

export const DocumentUpload: React.FC<DocumentUploadProps> = (props) => {
    const { companyId, close } = props;

    const [metadata, setMetadata] = useState<Metadata>({});
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const [upload, { loading, error }] = useMutation<DocumentAddMutation, DocumentAddMutationVariables>(
        MUTATION_ADD_DOCUMENT
    );

    const metadataDefinedForAllFiles =
        acceptedFiles?.length &&
        acceptedFiles.filter(({ name }: File) => metadata[name]?.description?.length).length === acceptedFiles.length;

    const uploadDocuments = useCallback(() => {
        if (!metadataDefinedForAllFiles) {
            return;
        }

        Promise.all(
            acceptedFiles.map(async (file: File) => {
                const { description: name } = metadata[file.name];

                try {
                    await upload({
                        variables: { data: { name, companyId, file } },
                        refetchQueries: [GET_COMPANY_DOCUMENTS]
                    });
                } catch (error) {
                    Sentry.captureMessage(`Document upload mutation failed: ${error}`, 'warning');
                }
            })
        ).finally(() => {
            close();
        });
    }, [metadataDefinedForAllFiles, acceptedFiles, close, metadata, upload, companyId]);

    return (
        <Form>
            <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                    <Icon name="plus circle" />
                    <FormattedMessage id="document.upload.message" />
                </div>
            </div>
            {acceptedFiles?.length > 0 && (
                <Table basic="very" compact="very" singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <FormattedMessage id="document.filename" />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <FormattedMessage id="document.description" />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {acceptedFiles.map(({ name }: File) => (
                            <Table.Row key={name}>
                                <Table.Cell style={{ width: '25%' }} singleLine>
                                    <Icon name={fileExtensionToIconName(name)} />
                                    {name}
                                </Table.Cell>
                                <Table.Cell style={{ width: '75%' }}>
                                    <Input
                                        fluid
                                        size="small"
                                        onChange={(_, { value }) =>
                                            setMetadata({
                                                ...metadata,
                                                [name]: { ...metadata[name], description: value }
                                            })
                                        }
                                        value={metadata[name]?.description}
                                    ></Input>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
            <Message negative hidden={!error}>
                {error?.message}
            </Message>
            <Button
                basic
                floated="left"
                onClick={(e) => {
                    e.preventDefault();
                    close();
                }}
            >
                <FormattedMessage id="cancel" />
            </Button>
            <Button
                primary
                floated="right"
                loading={loading}
                disabled={!metadataDefinedForAllFiles}
                onClick={uploadDocuments}
            >
                <FormattedMessage id="document.save" />
            </Button>
            <Divider section hidden />
        </Form>
    );
};
