import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { Error, Loader } from '@heltti/components';
import { getNodes, isValidGlobalId, parseDbId } from '@heltti/common';

import { useAppLanguage } from './AppLanguage';
import { GetUserProfileQuery } from '../graphql-schema';
import { useLoadingQuery } from '../hooks/loading-query';

export interface UserProfileContextProviderProps {
    isAuthenticated: boolean;
}

type CompanyAccess = {
    [companyId: string]: string;
};

export type UserProfileContextType = {
    id: string;
    firstName: string;
    lastName: string;
    companyAccess: CompanyAccess;
};

const QUERY_USER_PROFILE = gql`
    query GetUserProfile {
        root {
            me {
                id

                firstName
                lastName

                language {
                    id
                }

                companyAccess {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

const UserProfileContext = React.createContext<UserProfileContextType | undefined>(undefined);

const UserProfileContextProvider = ({
    isAuthenticated,
    children
}: PropsWithChildren<UserProfileContextProviderProps>) => {
    const { setLanguageId } = useAppLanguage();
    const { loading, data, error } = useLoadingQuery<GetUserProfileQuery>(QUERY_USER_PROFILE, {
        skip: !isAuthenticated
    });

    const { id: userId, firstName, lastName, language, companyAccess } = data?.root?.me ?? {};
    const { id: languageId } = language ?? {};

    useEffect(() => {
        if (userId && isValidGlobalId(userId)) {
            Sentry.setUser({ id: parseDbId(userId).toString() });
        }
        if (languageId) {
            setLanguageId(languageId);
        }
    }, [userId, languageId, setLanguageId]);

    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <Error />;
    }

    const value =
        userId && firstName && lastName && companyAccess
            ? {
                  id: userId,
                  firstName,
                  lastName,
                  companyAccess: getNodes(companyAccess).reduce(
                      (previous, access) => ({ ...previous, [access.id]: access.name }),
                      {}
                  )
              }
            : undefined;

    return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};

export const UserProfileConsumer = UserProfileContext.Consumer;
export const UserProfileProvider = UserProfileContextProvider;

export const useUserProfile = () => useContext<UserProfileContextType | undefined>(UserProfileContext);
