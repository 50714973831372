import React, { useCallback, useMemo } from 'react';
import { OperationVariables, TypedDocumentNode } from '@apollo/client';
import {
    LoadingQueryOptions,
    LoadingQueryResult as InnerLoadingQueryResult,
    OnlineStateChangeHandler,
    useCoreLoadingQuery
} from '@heltti/common';
import { Loader } from '@heltti/components';
import { DocumentNode } from 'graphql';

const EVENT_WS_ONLINE_STATE_CHANGED = 'EVENT_WS_ONLINE_STATE_CHANGED';
const loaderComponent = <Loader />;

export type LoadingQueryResult<TData, TVariables extends OperationVariables> = InnerLoadingQueryResult<
    TData,
    TVariables
>;

export function useLoadingQuery<
    TData,
    TVariables extends OperationVariables = OperationVariables,
    TSubscriptionData = TData,
    TSubscriptionVariables extends OperationVariables = OperationVariables
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: LoadingQueryOptions<TData, TVariables, TSubscriptionData, TSubscriptionVariables>
): LoadingQueryResult<TData, TVariables> {
    /**
     * Online document event handler
     */
    const onlineDocumentEventHandler = useCallback(
        (handler: OnlineStateChangeHandler) => (event: CustomEvent) => {
            const { isOnline } = event.detail;

            handler(isOnline);
        },
        []
    );

    /**
     * Add online event listener
     */
    const addOnlineEventListener = useCallback(
        (handler: OnlineStateChangeHandler) => {
            document.addEventListener(EVENT_WS_ONLINE_STATE_CHANGED, onlineDocumentEventHandler(handler));
        },
        [onlineDocumentEventHandler]
    );

    /**
     * Remove online event listener
     */
    const removeOnlineEventListener = useCallback(
        (handler: OnlineStateChangeHandler) => {
            document.removeEventListener(EVENT_WS_ONLINE_STATE_CHANGED, onlineDocumentEventHandler(handler));
        },
        [onlineDocumentEventHandler]
    );

    /**
     * Use loading query from lib
     */
    const innerResult = useCoreLoadingQuery(
        query,
        options,
        console.error,
        addOnlineEventListener,
        removeOnlineEventListener
    );

    return useMemo(
        () => ({
            ...innerResult,
            initialLoader: innerResult.loadingInitial ? loaderComponent : null
        }),
        [innerResult]
    );
}
