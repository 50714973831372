import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { v4 as uuid } from 'uuid';
import * as Sentry from '@sentry/react';

import { getCookie, makeSpanId, makeTraceId } from '@heltti/common';

import { config } from './config';
import { logout } from './ducks';
import { relayStylePagination } from '@apollo/client/utilities';

/**
 * Custom fetch function for adding operation name as query param to requests
 * NOTE:    The parameter is added solely for logging / monitoring / debugging purposes, the actual GraphQL payload
 *          shall be used from the request body
 *
 * @param uri       GraphQL endpoint configured when creating the http link
 * @param options   fetch options
 */
const customFetch = (uri: string, options: RequestInit) => {
    let operationName: string | undefined;

    try {
        operationName = JSON.parse(options.body as unknown as string)?.operationName;
    } catch {
        // No op
    }

    const transaction = Sentry.startTransaction({ op: 'http.client', name: operationName ?? 'unknown operation' });

    options.headers = {
        ...(options.headers || {}),
        // https://develop.sentry.dev/sdk/performance/#header-sentry-trace
        'sentry-trace': `${makeTraceId()}-${makeSpanId()}-${transaction?.sampled ? 1 : 0}`
    };

    return fetch(`${uri}?o=${operationName ?? 'unknown operation'}`, options)
        .then((result) => {
            transaction?.setStatus('ok');

            return result;
        })
        .catch((reason) => {
            transaction?.setStatus('unknown_error');

            return reason;
        })
        .finally(() => {
            transaction?.finish();
        });
};

const httpLink = createUploadLink({
    uri: `${config.apiUrl}/graphql/`,
    credentials: 'same-origin',
    fetch: customFetch
});

const contextLink = setContext(() => ({
    headers: {
        'X-Request-ID': uuid(),
        'X-CSRFToken': getCookie('csrftoken')
    }
}));

const cache = new InMemoryCache({
    dataIdFromObject: (o: any) => o.id,
    typePolicies: {
        ExtranetRootNode: {
            merge: true
        },
        CompanyNode: {
            fields: {
                employments: relayStylePagination(['first']),
                documents: relayStylePagination(['first'])
            }
        }
    }
});

export const setupWithAuthentication = (reduxStore) => {
    const logoutLink = onError(({ networkError, graphQLErrors }) => {
        const unauthError = graphQLErrors?.find(
            ({ message }) => message === 'You do not have permission to perform this action'
        );
        if (networkError?.message?.includes('403') || unauthError) {
            reduxStore.dispatch(logout());
            void client.resetStore();
        }
    });

    const client = new ApolloClient({
        link: contextLink.concat(logoutLink.concat(httpLink)),
        cache
    });

    return client;
};
