import { gql } from '@apollo/client';

import {
    companyRepresentativeFragment,
    companyFragment,
    companyInsurancesListFragment,
    companyInvoicingFragment,
    departmentFragment
} from './fragments';

export const companyQuery = gql`
    query CompanyQuery($companyId: ID!) {
        root {
            company(id: $companyId) {
                ...CompanyFragment

                representatives {
                    ...CompanyRepresentativeFragment
                }

                calendar {
                    id
                    fiscalYearEvents: events(eventTypes: ["COMPANY_FISCAL_PERIOD"], last: 1) {
                        edges {
                            node {
                                id
                                start
                                end
                            }
                        }
                    }
                }

                insurances {
                    edges {
                        node {
                            ...CompanyInsurancesListFragment
                        }
                    }
                }

                invoicing {
                    ...CompanyInvoicingFragment
                }
            }
        }
    }

    ${companyFragment}
    ${companyInsurancesListFragment}
    ${companyInvoicingFragment}
    ${companyRepresentativeFragment}
`;

export const companyDepartmentsQuery = gql`
    query CompanyDepartmentsQuery($companyId: ID!) {
        root {
            company(id: $companyId) {
                departments {
                    edges {
                        node {
                            ...DepartmentFragment
                        }
                    }
                }
            }
        }
    }

    ${departmentFragment}
`;
