import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { isValidGlobalId, parseDbId } from '@heltti/common';

import { getMessages } from '../translations';

export enum AppLanguage {
    FI = 'fi',
    EN = 'en'
}

const APP_LANGUAGE_IDS = {
    '1': AppLanguage.FI,
    '3': AppLanguage.EN
};

export type AppLanguageContextType = {
    language: AppLanguage;
    setLanguage: (language: AppLanguage) => void;
    setLanguageId: (id: string) => void;
};

const AppLanguageContext = React.createContext<AppLanguageContextType>({
    language: AppLanguage.FI,
    setLanguage: () => {
        // Will be overridden in AppLanguageContextProvider
    },
    setLanguageId: () => {
        // Will be overridden in AppLanguageContextProvider
    }
});

const AppLanguageContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const [language, setLanguage] = useState<AppLanguage>(AppLanguage.FI);

    // Helper callback function to set the language using a language db id
    const setLanguageId = useCallback(
        (languageId: string) => {
            const id = parseDbId(languageId);
            const lang = isValidGlobalId(languageId) && id in APP_LANGUAGE_IDS ? APP_LANGUAGE_IDS[id] : AppLanguage.FI;
            setLanguage(lang);
            document.documentElement.lang = lang;
        },
        [setLanguage]
    );

    const value = useMemo(() => ({ language, setLanguage, setLanguageId }), [language, setLanguageId]);

    return (
        <AppLanguageContext.Provider value={value}>
            <IntlProvider locale={language} messages={getMessages(language)}>
                {children}
            </IntlProvider>
        </AppLanguageContext.Provider>
    );
};

export const AppLanguageConsumer = AppLanguageContext.Consumer;
export const AppLanguageProvider = AppLanguageContextProvider;

export const useAppLanguage = () => useContext<AppLanguageContextType>(AppLanguageContext);
